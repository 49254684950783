/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-filename-extension */
import React, { Component } from 'react';

import Header from 'parts/Header';
import Footer from 'parts/Footer';

import Maintenance from 'assets/images/maintenance.png';

export default class LandingPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <Header {...this.props} />
        <div className="flex w-full justify-center">
          <img src={Maintenance} alt="Not Found" className="sm:w-2/4 xl:w-4/12" />
        </div>
        <h1 className="text-black text-2xl text-center my-10">
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          Estamos em manutenção
        </h1>
        <Footer />
      </>
    );
  }
}
